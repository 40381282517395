import React from 'react';
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';

import {theme} from '../../themes/default-theme';
import ForSaleHero from '../ForSaleHero';
import CaravanCard from '../../components/navigation/CaravanCard';
import CommunityContainer from '../../containers/Community/CommunityContainer';
import Text from '../../components/typography';
// import NewHeroBanner from '../../components/multimedia/NewHeroBanner';
import Banner from '../../components/multimedia/Banner';
import ForSaleFilters from '../ForSaleFilters';
import CategoryCarousel from '../../components/multimedia/CategoryCarousel';
import AvailableStock from '../../components/multimedia/AvailableStock';

const CaravanCardContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;

  ${theme.breakpoints.up('md')} {
    max-width: 335px;
  }
`

const Wrapper = styled.div`

  ${theme.breakpoints.up('lg')} {
    padding-top: 87px;
  }
`

const Container = styled.div`
  max-width: 1164px;
  margin: 0 auto;
  h1 {
    font-weight: 700;
  }
  ${theme.breakpoints.up('md')} {
    h1 {
      font-size: 28px;
    }
  }
  ${theme.breakpoints.down('sm')} {
    h1 {
      font-size: 18px;
    }
  }
`

const Articles = styled.div`
  margin: 80px 0 120px;

  ${theme.breakpoints.up('md')} {
    margin: 60px 0 80px;
  }
`

const FilterContainer = styled.div`
  margin-bottom: 50px;
  margin-top: 20px;

  hr {
    height: 1px;
    border: none;
    background-color: ${theme.palette.middleGrey};
  }

  ${theme.breakpoints.up('md')} {
    margin-bottom: 90px;
    margin-top: 55px;

    hr {
      margin-top: 20px;
    }
  }
`

const CategoriesContainer = styled.div`
  margin-bottom: 5px;
`

const ForSaleLanding = (props) => {
  
  const data = props.pageData ? props.pageData : props

  console.log('ForSaleLanding', props);
  
  return (
  	<Wrapper>
      {data.heroBanner &&
        <Banner
          type="mediumBanner"
          mainHeading={data.heroBanner.title}
          headingSize="extraLarge"
          bgImage={data.heroBanner.image}
          navType={null}
          subHeading2={data.heroBanner.subtitle}
          subHeading2Tag="h1"
          hTag="h2"
        />
      }

      {data.filters &&
        <Grid container spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'flex-start'} >
          <Grid item xs={12}>
            <Box px={0}>
              <FilterContainer>
                <ForSaleFilters
                  filters={data.filters}
                />
                <Box px={2}>
                  <hr />
                </Box>
              </FilterContainer>
            </Box>
          </Grid>
        </Grid>
      }

      {data.categories &&
        <CategoriesContainer>
          <CategoryCarousel 
              title={data.categories.title}
              categories={data.categories.items}
          />
        </CategoriesContainer>
      }
      
      <AvailableStock
        cta={{
          href: '/for-sale/all',
          title: 'View All Dealer Stock',
          style: 'outline'
        }}
        rates={data.rates ? data.rates : undefined}
      />

      {(data.articleSections && data.articleSections.length > 0) &&
        data.articleSections.map(item => {
          return (
            <Articles key={item.title}>
              <Container>
                <Grid container direction={'row'} justifyContent={'center'}>
                  <CommunityContainer
                    title={item.title}
                    articles={item.articles}
                    borderRadius={false}
                    cta={item.cta}
                    regularTabletStyling
                  />
                </Grid>
              </Container>
            </Articles>
          )
        })
      }
    </Wrapper>
  )
}
  
ForSaleLanding.propTypes = {
};
  
ForSaleLanding.defaultProps = {
};
  
export default ForSaleLanding;