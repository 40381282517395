import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {ReactSVG} from 'react-svg';
import styled from 'styled-components';
import {v4 as uuidv4} from 'uuid';
import ReactHtmlParser from 'react-html-parser';

import NextLink from '../NextLink';
import {theme} from '../../../themes/default-theme';
import {RespImg, Icon} from '../../ui';
import Text from '../../typography';
import Checkbox from '../../forms/Checkbox';

import bedIcon from '../../../assets/icons/bed.svg';
import toiletIcon from '../../../assets/icons/toilet.svg';
import showerIcon from '../../../assets/icons/shower.svg';
import heartIcon from '../../../assets/icons/heart.svg';
import heartFilledIcon from '../../../assets/icons/heartFilled.svg';
import questionIcon from '../../../assets/icons/question.svg';


const Container = styled.div`
  position: relative;
  overflow: hidden;
  ${props => props.bottomBorder && ` {
    border-bottom: 1px solid ${theme.palette.middleGrey};
  }`
  }

  ${theme.breakpoints.down('sm')} {
    padding: 20px 0;
  }

  ${props => props.marginBottom && `
    padding: 20px 0;
  `}

  ${theme.breakpoints.up('md')} {
    border-bottom: 4px solid transparent;
    padding-top: 0;
  }

  // @media (hover: hover) {
  //   ${theme.breakpoints.up('md')} {
  //     &:hover {
  //       border-bottom: 4px solid ${theme.palette.yellow};
  //       .caravan-card-border {
  //         &:after {
  //           opacity: 1;
  //         }
  //       }
  //     }
  //   }
  // }
  
  .caravan-card-title {
    text-align: left;

    ${theme.breakpoints.up('md')} {
      padding: 0 40px;
      text-align: center;

      ${props => props.sp === 1 && `
        padding: 0 5px;
      `}
    }
  }

  ${theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;

    ${props => props.fullWidth && `
      display: block;
      
      .caravan-card-title {
        text-align: center;
      }
    `}
  }
`

// + .caravan-card-content {
//   .caravan-card-checkbox {
//     display: none;
//   }
// } 


const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  a {
    display: block;
    width: 100%;
  }

  ${theme.breakpoints.down('sm')} {
    width: 50%;
    margin-right: 20px;
    ${props => props.fullWidth && `
      width: 100%;  
    `}
  }

  picture {
    padding: 5px 0;
    position: relative;
    z-index: 2;

    ${theme.breakpoints.up('md')} {
      padding: 25px 30px;
    }
  }

  ${theme.breakpoints.up('md')} {
    // &:before {
    //   content: ' ';
    //   width: 100%;
    //   height: 232px;
    //   position: absolute;
    //   background-image: linear-gradient(180deg, #F3F4F7 9%, rgba(255,255,255,0.00) 59%);
    //   border-radius: 40px 0 0 0;
    //   top: 50%;
    // }

    &:after {
      content: ' ';
      width: 100%;
      height: 1000px;
      position: absolute;
      border: 4px solid ${theme.palette.yellow};
      top: 50%;
      border-top-left-radius: 40px;
      opacity: 0;
    }
  }

`

const ContentContainer = styled.div`
  position: relative;
  width: 50%;

  ${props => props.fullWidth && `
    width: 100%;  
  `}

  a {
    text-decoration: none;
  }
  
  h6 {
    min-height: 52px;
    ${theme.breakpoints.down('sm')} {
      margin-bottom: 10px;
    }
  }

  ${theme.breakpoints.up('md')} {
    width: auto;
  }
`

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  border-radius: 4px;
  border: 1px solid ${theme.palette.middleGrey};
  max-width: 210px;
  min-height: 36px;

  ${theme.breakpoints.up('md')} {
    padding: 11px 27px;
    margin: 13px auto;
    min-height: 50px;
  }
`

const DetailsItem = styled.div`
  display: flex;
  align-items: center;
`

const DetailsItemIcon = styled.div`
  svg {
    height: 22px;
    width: auto;
    display: block;

    ${theme.breakpoints.up('md')} {
      height: 26px;
    }
  }
`

const DetailsItemText = styled(Text)`
  margin-left: 5px;

  ${theme.breakpoints.up('md')} {
    margin-left: 10px;
  }
`

const Save = styled.button`
  margin-top: 12px;
  display: inline-block;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  outline: 0;
  position: absolute;
  right: 1px;

  svg {
    width: 20px;
    height: 20px;
    display: block;
    [fill] {
      fill: ${theme.palette.skyBlue};
    }

    ${theme.breakpoints.up('md')} {
      width: 24px;
      height: 24px;
    }
  }

  ${theme.breakpoints.up('md')} {
    right: 10px;
    transform: translateY(-50px);
    margin-top: 0;
  }
`

const CompareContainer = styled.div`

  ${theme.breakpoints.up('md')} {
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const ToolTip = styled.div`
  position: absolute;
  bottom: 8px;
  right: 0;

  ${theme.breakpoints.up('md')} {
    right: 10px;
    top: 0;
    bottom: auto;
  }
`

const TextContainer = styled.div`
  margin-top: 5px;
  padding: 0 5px;

  ${theme.breakpoints.down('sm')} {
    * {
      text-align: left;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
`

const CaravanCard = ({
  href,
  as,
  image,
  title,
  seriesType,
  size,
  seriesDetails,
  onSave,
  saved,
  compare,
  disableCompare,
  onCompare,
  toolTip,
  onTooltipClick,
  checkboxName,
  text,
  textLinks,
  fullWidth,
  bottomBorder
}) => {
  
  const [isSaved, setSaved] = useState(saved);

  // console.log('cc image', image)

  const toggleSaved = () => {
    // console.log('is saved', isSaved);
    onSave(!isSaved);
    setSaved(!isSaved);
  }

  useEffect(() => {
    setSaved(saved);
  }, [saved])

  return (
  	<Container marginBottom={!seriesDetails} sp={!toolTip ? 1 : 0} fullWidth={fullWidth} bottomBorder={bottomBorder}>
      <ImgContainer className="caravan-card-border" fullWidth={fullWidth}>
        <NextLink href={href} as={as}>
          <a>
            <RespImg
              {...image}
              type="png"
              lazyLoad={false}
            />
          </a>
        </NextLink>
      </ImgContainer>
      <ContentContainer className="caravan-card-content" fullWidth={fullWidth}>
        {toolTip && 
          <ToolTip>
            <Icon onClick={onTooltipClick} infoGrey small>
              <ReactSVG src={questionIcon} />
            </Icon>
          </ToolTip>
        }
        <NextLink href={href} as={as}>
          <a>
            <Text weight="800" variant="h4" uppercase colour="vividBlue" align="center" className="caravan-card-title" >{ReactHtmlParser(title)}</Text>
          </a>
        </NextLink>
        {(seriesType || size) &&
          <Text variant="subtitle1" uppercase align="center">{seriesType}{seriesType ? ' | ' : ''}{size}</Text>
        }
        {text && 
          <TextContainer>
            <Text variant="body1" align="center">
              <div>
                {text}
              </div>
              {textLinks && textLinks.map((link, index) => {
                return( 
                  <span key={link.text}>
                    <NextLink href={link.href}><a>{link.text}</a></NextLink>
                    {index + 1 !== textLinks.length ? ', ' : ' '}
                  </span>
                )
              })}
            </Text>
          </TextContainer>
        }
        {seriesDetails &&
          <Details>
            {(seriesDetails.sleepsMin || seriesDetails.sleepsMax) &&
              <DetailsItem>
                <DetailsItemIcon>
                  <ReactSVG src={bedIcon} />
                </DetailsItemIcon>
                <DetailsItemText variant="body2">
                  Up to {seriesDetails.sleepsMax}
                </DetailsItemText>
              </DetailsItem>
            }
            {seriesDetails.shower &&
              <DetailsItem>
                <DetailsItemIcon>
                  <ReactSVG src={showerIcon} />
                </DetailsItemIcon>
              </DetailsItem>
            }
            {seriesDetails.toilet &&
              <DetailsItem>
                <DetailsItemIcon>
                  <ReactSVG src={toiletIcon} />
                </DetailsItemIcon>
              </DetailsItem>
            }
          </Details>
        }
        {onSave &&
          <Save onClick={toggleSaved}>
            <ReactSVG src={saved ? heartFilledIcon : heartIcon} />
          </Save>
        }
        {onCompare &&
          <CompareContainer>
            <Checkbox
              className="caravan-card-checkbox"
              disabled={disableCompare}
              label="Compare"
              checked={compare}
              name={checkboxName || uuidv4()}
              onChange={(event) => onCompare && onCompare(event.target.checked)}
            />
          </CompareContainer>
        }
      </ContentContainer>
    </Container>
  )
}
  
CaravanCard.propTypes = {
  href: propTypes.string,
  image: propTypes.object.isRequired,
  title: propTypes.string.isRequired,
  seriesType: propTypes.string,
  size: propTypes.string,
  seriesDetails: propTypes.shape({
    sleepsMin: propTypes.any,
    sleepsMax: propTypes.any,
    shower: propTypes.bool,
    toilet: propTypes.bool
  }),
  saved: propTypes.bool,
  onSave: propTypes.func,
  onCompare: propTypes.func,
  disableCompare: propTypes.bool,
  compare: propTypes.bool,
  toolTip: propTypes.object,
  onTooltipClick: propTypes.func,
  as: propTypes.string,
  checkboxName: propTypes.string,
  text: propTypes.string,
  textLinks: propTypes.array,
  bottomBorder: propTypes.bool
}

CaravanCard.defaultProps = {
  seriesDetails: null,
  seriesType: null,
  size: null,
  saved: false,
  onSave: null,
  onCompare: null,
  disableCompare: false,
  compare: undefined,
  toolTip: null,
  onTooltipClick: null,
  as: undefined,
  checkboxName: null,
  text: null,
  textLinks: null,
  bottomBorder: true
}
  
export default CaravanCard