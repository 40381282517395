import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {get} from 'lodash';
import {TextField} from '@material-ui/core';
import Router from 'next/router';
import {Grid} from '@material-ui/core';
import {Loader} from 'google-maps';
import axios from 'axios';
import {theme} from '../../../src/themes/default-theme';
import refactorDealerData from '../../../src/data-restructure/pages/dealer'
import latestTimestamp from '../../../src/util/latestPostcodeTimestamp';

import getClosestCoordinate from '../../../src/util/getClosestCoodinate';
import getClosestDealersByPostcode from '../../../src/util/getClosestDealersByPostcode';
import getFallbackStateAus from '../../../src/util/getFallbackStateAus';
import postcodeRefactor from '../../../src/util/postcodeRefactor';


// components
import Banner from '../../../src/components/multimedia/Banner';
import BannerSlider from '../../../src/components/multimedia/BannerSlider';
import Text from '../../../src/components/typography';
import Checkbox from '../../../src/components/forms/Checkbox';
import GetLocation from '../../../src/containers/GetLocation';
import getClosestDealer from '../../../src/util/getClosestDealer';
import BodyText from '../../../src/components/content/BodyText';

const Container = styled.div`
  margin: 35px 0;

  ${theme.breakpoints.up('md')} {
    margin: 55px 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 90px 0;
  }
`

const BodyTextContainer = styled.div`
  max-width: 780px;
  margin: 35px auto 0;

  ${theme.breakpoints.up('md')} {
    margin: 55px auto 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 110px auto 0;
  }
`

const FormBox = styled.div`
  width: 100%;
  max-width: 470px;
  margin: 0 auto;
  padding: 40px 15px;
  background-color: ${theme.palette.lightGrey};
  border-bottom-right-radius: 50px;
  text-align: center;

  ${theme.breakpoints.up('md')} {
    padding: 50px 40px;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 0;
  }

  ${theme.breakpoints.up('lg')} {
    max-width: 550px;
    padding: 50px;
    border-bottom-left-radius: 60px;
  }
`

const Form = styled.div`
  margin-top: 25px;
`

const Inputs = styled.div`
  max-width: 250px;
  margin: 15px auto 0;

  ${theme.breakpoints.up('md')} {
    margin: 20px auto 0;
  }

  ${theme.breakpoints.up('lg')} {
    margin: 30px auto 0;
  }
`

const Error = styled.p`
  color: ${theme.palette.error.form};
  text-align: center;
`

const FindADealer = (props) => {

  const {
    dealers,
  } = props

  console.log('find a dealer', props);

  const dealerUri = '/dealer/';
  const countryCode = props.countryProps.countryCode;
  const [filteredDealers, setFilteredDealers] = useState(dealers);
  const [geocoder, setGeocoder] = useState(null);
  const [checked, updateChecked] = useState(false);
  const [postcodeError, updatePostcodeError] = useState(false);
  const [postcodes, setPostcodes] = useState(null);


  const motorhomeCapvervanDealers = dealers ? dealers.filter(item => {
    if (get(item, 'acf_dealers.motorhomes', '') === "1" || get(item, 'acf_dealers.campervans', '') === "1") {
      return true
    }
  }) : [];

  useEffect(() => {
    const asyncLoad = async () => {
      const loader = new Loader(process.env.GOOGLE_MAPS_API_KEY, {});
      const google = await loader.load();
      setGeocoder(new google.maps.Geocoder())
    }
    asyncLoad()
  }, [])

  useEffect(() => {
    const getPostcodes = () => {
      //setPostcodeLoading(true);
      axios.get('/data/postcodes.json', {})
      .then(function ( response) {
        // handle success
        const lsPostcodes = {
          date: Date.now(),
          data: response.data
        }
        setPostcodes(lsPostcodes.data);
        localStorage.setItem('postcodes', JSON.stringify(lsPostcodes))
        //setPostcodeLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //setPostcodeLoading(false);
      })
      .then(function () {
        // always executed
        //setPostcodeLoading(false);
      });
    }

    if (localStorage && localStorage.getItem('postcodes')) {
      const lsPostcodes = JSON.parse(localStorage.getItem('postcodes'));
      if (lsPostcodes.data && lsPostcodes.date > latestTimestamp) {
        setPostcodes(lsPostcodes.data);
      } else {
        getPostcodes();
      }
    } else {
      getPostcodes();
    }
  }, []) 

  useEffect(() => {
    setFilteredDealers(dealers);
  }, [dealers])

  useEffect(() => {

    const _filteredDealers = checked ? dealers.filter(item => {
      if (get(item, 'acf_dealers.motorhomes', '') === "1" || get(item, 'acf_dealers.campervans', '') === "1") {
        return true
      }
    }) : dealers;

    setFilteredDealers(_filteredDealers);
  }, [checked])

  const getGeoDealerFromPostcode = (postcode) => {

    const country = props.countryProps.countryCode === 'AU' ? 'Australia' : 'New Zealand'
    const fallback = get(filteredDealers[0], 'acf_dealers.country') === 'Australia' ? getFallbackAus(postcode) : getFallbackNz(postcode);

    geocoder.geocode( { 'address': 'postalcode+' + postcode + country}, (results, status) => {
      if (status == 'OK') {
        const latFunc = get(results, '[0].geometry.location.lat', null);
        const lngFunc = get(results, '[0].geometry.location.lng', null);

        if (latFunc && lngFunc) {
          const latLng = {
            latitude: latFunc(),
            longitude: lngFunc()
          }

          const closest = getClosestCoordinate(latLng, filteredDealers.map(item => {
            return {
              ...item,
              longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
              latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
            }
          }), filteredDealers);

          if (closest && closest.item && closest.item.uri) {
            console.log('closest', closest);
            Router.push(dealerUri + closest.item.uri).then(() => window.scrollTo(0, 0));
            saveDealer(closest)
          } else {
            console.log('error updatePostcodeError');
            updatePostcodeError(true);
            // fallback && fallback.uri && Router.push(dealerUri + fallback.uri).then(() => window.scrollTo(0, 0));
            // saveDealer(fallback)
            // console.log('fallback1', fallback);
          }
        }
      } else {
        updatePostcodeError(true);
        // fallback && fallback.uri && Router.push(dealerUri + fallback.uri).then(() => window.scrollTo(0, 0));
        // saveDealer(fallback)

      }
    });
  }

  const getFallbackAus = (post_code) => {
    return getFallbackStateAus(post_code, filteredDealers);
  }

  const getFallbackNz = (post_code) => {
    const postcode = parseInt(post_code);
    const closestDealer = getClosestDealersByPostcode(postcode, filteredDealers, 1);
    return closestDealer[0]
  }

  const saveDealer = (dealer) => {
    const data = dealer && dealer.item ? dealer.item : dealer
    const refactoredData = refactorDealerData(data);
    
    // if dealer is sales then save to LS
    if (get(data, 'acf_dealers.sales', '') === '1') {

      data && localStorage.setItem('dealer', JSON.stringify({
        title: data.title,
        uri: data.uri,
        data: refactoredData
      }));

    }
  }

  const getDealerFromPostcode = (_postcode) => {

    let postcode = _postcode;

    const postcodeFromTo = postcodeRefactor

    postcodeFromTo.forEach(item => {
      if (item.from === _postcode.toString()) {
        postcode = item.to
      }
    })

    if (checked) {
      getGeoDealerFromPostcode(postcode);
    } else {
      const closestDealer = getClosestDealer(postcode, filteredDealers, countryCode);
      if (closestDealer) {
        closestDealer.uri && Router.push(dealerUri + closestDealer.uri).then(() => window.scrollTo(0, 0));
        saveDealer(closestDealer)
      } else {
        getGeoDealerFromPostcode(postcode);
        // const fallback = get(filteredDealers[0], 'acf_dealers.country') === 'Australia' ? getFallbackAus(postcode) : getFallbackNz(postcode);      
        // saveDealer(fallback)
        // fallback && fallback.uri && Router.push(dealerUri + fallback.uri);
      }
    }
  }

  const getDealerFromLocation = (location) => {
    const closest = getClosestCoordinate(location, dealers.map(item => {
      return {
        ...item,
        longitude: parseFloat(get(item, 'acf_dealers.longitude', '')),
        latitude: parseFloat(get(item, 'acf_dealers.latitude', '')),
      }
    }), filteredDealers);
    if (closest && closest.item && closest.item.uri) {
      saveDealer(closest);
      window.location.href = dealerUri + closest.item.uri
      // Router.push(dealerUri + closest.item.uri).then(() => window.scrollTo(0, 0)); // Doesn't work in older versions of chrome and firefox when getting location
    } else {
      const fallback = get(filteredDealers[0], 'acf_dealers.country') === 'Australia' ? getFallbackStateAus(postcode, filteredDealers) : filteredDealers[0];
      saveDealer(fallback);
      window.location.href = dealerUri + closest.item.uri;
      // fallback && fallback.uri && Router.push(dealerUri + fallback.uri).then(() => window.scrollTo(0, 0)); // Doesn't work in older versions of chrome and firefox when getting location
    }
  }

  const handleSubmit = (location, type) => {
    if (type === 'postcode') {
      const postcode = get(location, 'postcode', null)
     
      if (postcodes.find(item => item.postcode.toString() === postcode.toString())) {
        updatePostcodeError(false)
        getDealerFromPostcode(postcode)
      } else {
        updatePostcodeError(true)
      }
  
    } else {
      getDealerFromLocation(location);
    }
  }

  console.log('fad', dealers);


  return (
  	<div>
      {props.heroBanner &&
        <Banner
          {...props.heroBanner}
          largePaddingTop={true}
        />
      }
      {props.heroBannerSlider &&
        <BannerSlider
          {...props.heroBannerSlider}
        />
      }

      {props.bodyText &&
        <Grid container className={'hasPadding'} spacing={0} direction={'row'} wrap="nowrap" justify={'space-between'} align={'center'}>
          <Grid item xs={12}>
            <BodyTextContainer>
              <BodyText 
                {...props.bodyText}
                headerType="h4"
                align="center"
              />
            </BodyTextContainer>
          </Grid>
        </Grid>
      }

      <Container>
        <FormBox>
          <Text variant="body1" align="center">Selected Jayco dealerships sell Motorhomes / Campervans. Please tick the checkbox below to find your licensed motorised dealer.</Text>

          <Form>
            {motorhomeCapvervanDealers.length > 1 &&
              <Checkbox
                label="Motorhomes / Campervans"
                value={checked}
                onChange={(event) => updateChecked(event.target.checked)}
                name='motorhomes_campervans'
              />
            }

            <Inputs>
              <GetLocation 
                onSubmit={handleSubmit}
              />
            </Inputs>

            {postcodeError && 
              <Error>Sorry, we can't find a dealer with that postcode.</Error>
            }
            
          </Form>

        </FormBox>
      </Container>
    </div>
  )
}
  
FindADealer.propTypes = {
}

FindADealer.defaultProps = {
}

export default FindADealer