import React from 'react';
import StockContainer from './Container';
import styled from 'styled-components';

import {theme} from '../../themes/default-theme';
import Quicklinks from '../../components/navigation/Quicklinks';

const Container = styled.div `


`

const Stock = (props) => {
  console.log('STOCK PROPS', props);
  return (
  	<Container>
      <StockContainer {...props} />
      <Quicklinks 
        dealerComponent={true}
      />
    </Container>
  )
}
  
Stock.propTypes = {
}
  
Stock.defaultProps = {
}
  
export default Stock