import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {theme} from '../../../themes/default-theme';
import ReactHtmlParser from 'react-html-parser';
import {get} from 'lodash';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import cx from 'clsx';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '../Table';
import CompareTable from '../../../containers/Compare/Table';

const ExpansionPanelStyled = styled(props => {
  const classes = cx(`active-bg-${props.activeBg}`, props.className);
  return <Accordion {...props} className={classes} activeBg={undefined} border={undefined}/>
})`

  > div {
    padding-left: 0;
    padding-right: 0;
  }
  
  & {
    margin: 0px !important;
    ${props => props.border === 1 && `
      border-bottom: 1px solid ${theme.palette.middleGrey};
    `}
    border-bottom: none;
    box-shadow: none;
  }
  .MuiAccordionSummary {
    &-root {
      ${props => props.size === 'small' ? `
        height: 50px;
        &.Mui-expanded {
          min-height: 50px;
        }
      ` : `
        min-height: 60px;
      `}
      padding-left: 0;
      padding-right: 0;
      background: ${theme.palette.lightGrey};
    }
    &-expandIcon {
      ${props => props.size === 'small' ? `
        width: 24px;
        height: 24px;
      `: `
        width: 30px;
        height: 30px;
      `}
      margin-right: 0px;
      color: #758094;

      ${theme.breakpoints.down('sm')} {
        margin-right: 0px;
      }
    }
  }

  ${props => props.border === 1 && `
    border-bottom: 1px solid ${theme.palette.middleGrey} !important;
    border-radius: 0 !important;
  `}

  &.MuiAccordion-root:last-child {

    
  }
  .Mui-expanded {
    &.MuiAccordionSummary-content {
      ${props => props.size === 'small' ? `
        height: 50px;
        min-height: 50px;
        padding: 15px 0;
      ` : `
        height: 60px;
      `}
    }
    background: ${theme.palette.vividBlue};
    transition-duration: 0s;
    &.MuiAccordionSummary-expandIcon {
      transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    .MuiTypography-root {
      color: ${theme.palette.white};
    }
  }

  &.active-bg-middleGrey {
    .Mui-expanded {
      background: transparent;

      .MuiTypography-root {
        color: ${theme.palette.charcoal};
      }
      &.MuiAccordionSummary-expandIcon {
        transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
`;

const TypographyHeading =  styled(props => {
  return <Typography {...props} />
})`
  & {
    ${props => props.size === 'small' ? `
      font-size: 14px;
      text-transform: uppercase;
      letter-spacing: 2px;
    `: `font-size: 20px;`}
    font-weight: 700;
    display: flex;
    align-items: center;
    text-align: left;
    color: ${theme.palette.charcoal};
    ${theme.breakpoints.down('sm')} {
    ${props => props.size === 'small' ? `
      font-size: 14px;
    `: `font-size: 14px;`
    }
      font-weight: 600;
    }
  }
`;

const ExpansionPanelDetailsStyled =  styled(props => {
  return <AccordionDetails {...props} />
})`
  & {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
    color: ${theme.palette.gunmentalGrey};
    text-align: left;
    ${theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
    ${props => props.padding === 0 && `
      padding: 0;
    `}
  }
`;

export const AccordionOutput = props => {
  const {data, onChange, id, onExpand, title, firstActive, variant} = props;
  const [expanded, setExpanded] = React.useState(firstActive ? get(data, '[0].title', false) : (props.defaultExpanded ? props.defaultExpanded : false));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    onChange(panel);
  };

  useEffect(() => {
    onExpand(expanded)
  }, [expanded]);



  return (
    <div>
      {data && data.length > 0 ? data.map(function(accordion, index) {
        return (
          <AccordionItem
            key={`${id}_${index}`}
            index={index}
            accordion={accordion}
            expanded={expanded}
            handleChange={handleChange}
            defaultExpanded={props.defaultExpanded ? props.defaultExpanded : undefined}
            cellWidth={props.cellWidth || null}
            {...props}
          />
        )
      }):
        <AccordionItem 
          accordion={{
            title: title,
          }}
          index={0}
          handleChange={handleChange}
          defaultExpanded={props.defaultExpanded ? props.defaultExpanded : undefined}
          expanded={expanded}
          cellWidth={props.cellWidth || null}
          {...props}
        />
      }
    </div>
  );
}

const AccordionItem = (props) => {

  const {accordion, handleChange, id, compare, highlight, compareItems, index, expanded, defaultExpanded, border, variant} = props;

  return (
    <ExpansionPanelStyled defaultExpanded={defaultExpanded ? defaultExpanded : false} id={`${id}_${index}`} border={border ? 1 : 0} size={props.size} variant={variant} expanded={defaultExpanded ? undefined : (expanded === accordion.title)} onChange={handleChange(accordion.title)} activeBg={props.activeBg}>
      <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={accordion.title + "bh-content"}
      id={id + accordion.title + "bh-header"}
      >
        <TypographyHeading size={props.size}>{accordion.title}</TypographyHeading>
      </AccordionSummary>
      <ExpansionPanelDetailsStyled padding={(accordion.table || compare) ? 0 : 1}>
        {accordion.caption &&
          <Typography>
            {ReactHtmlParser(accordion.caption)}
          </Typography>
        }
        {accordion.table && 
          <Table
            key={`table_${id}`}
            sideBorder={false}
            {...accordion.table}
          />
        }
        {
          compare &&
          <CompareTable
            rows={accordion.rows}
            highlight={highlight}
            compareItems={compareItems}
            cellWidth={props.cellWidth || null}
            showBlankCell={props.showBlankCell}
          />
        }
        {props.children &&
          props.children
        }
      </ExpansionPanelDetailsStyled>
    </ExpansionPanelStyled>
  )
}

AccordionOutput.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onExpand: PropTypes.func,
  id: PropTypes.string.isRequired,
  compare: PropTypes.bool,
  highlight: PropTypes.bool, // for compare
  compareItems: PropTypes.array,
  activeBg: PropTypes.string,
  size: PropTypes.oneOf(['small', 'regular']),
  defaultExpanded: PropTypes.bool,
  border: PropTypes.bool,
  cellWidth: PropTypes.string,
  showBlankCell: PropTypes.bool,
  firstActive: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

AccordionOutput.defaultProps = {
  data: [],
  onChange: () => {},
  onExpand: () => {},
  id: 'panel',
  compare: false,
  highlight: false,
  compareItems: [],
  activeBg: 'vividBlue',
  children: null,
  size: 'regular',
  defaultExpanded: false,
  border: true,
  cellWidth: null,
  showBlankCell: false,
  firstActive: false,
  variant: 'primary'
};

export default AccordionOutput;