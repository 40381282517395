import React, {useState, useEffect} from 'react';
import propTypes from 'prop-types';
import {findIndex, get} from 'lodash';
import {Grid, Box} from '@material-ui/core';
import styled from 'styled-components';


import {theme} from '../../themes/default-theme';
import Text from '../../components/typography';
import {Tabs, Table, Cta, Links} from '../../components/ui';
import toPrice from '../../util/toPrice';
import {getItems, saveItem, removeItem} from '../../util/savedCaravans';
import SocialShare from '../../components/ui/SocialShare';
import ThumbnailCarousel from '../../components/multimedia/ThumbnailCarousel';
import StockCard from '../../components/navigation/StockCard';
import getClosestNumber from '../../util/getClosestNumber';
import dlPush from '../../util/dlPush'
import ModalGallery from '../../components/multimedia/ModalGallery';

import saveSvg from '../../assets/icons/heart.svg';
import savedSvg from '../../assets/icons/heartFilled.svg';
import chevronLeft from '../../assets/icons/chevron-left-white.svg';
import StockEnquiryForm from '../StockEnquiryForm';
import CalculatorBannerModal from '../../components/multimedia/CalculatorBannerModal';
import Breadcrumbs from '../../components/navigation/NewBreadcrumbs';

const GridOrder = styled(props => {
  return <Grid {...props} mdorder={undefined}/>
})`
  ${theme.breakpoints.up("md")} {
    order: ${props => props.mdorder};
  }

    > div {
      height: 100%;
    }
`

const Container = styled.div`
  overflow: hidden;
`

const TabsContainer = styled.div `
  margin-top: 25px;
  
  ${theme.breakpoints.up("md")} {
    max-width: 480px;
  }
`

const Content = styled.div`
  margin-top: 30px;

  ${theme.breakpoints.down("md")} {
    .MuiTypography-root {
      font-size: 15px;
    }
  }

  ${theme.breakpoints.up("lg")} {
    max-width: 720px;
  }
`

const TableWrapper = styled.div`
  border: 1px solid ${theme.palette.middleGrey};
  border-top: 0;
`

const Ctas = styled.div`
  margin-top: 18px;

  ${theme.breakpoints.up("md")} {
    margin-top: 28px;
  }
`

const CtaItem = styled.div`
  margin-bottom: 20px;
  min-width: 210px;
  margin-bottom: 20px;
  max-width: 300px;

  ${theme.breakpoints.up("md")} {
    display: inline-block;
    max-width: 100%;
    margin-right: 20px;
    min-width: 240px;
  }
`

const LinkItem = styled.div`
  display: inline-block;
  margin-right: 30px;
  margin-top: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-right: 0;
  }

  ${theme.breakpoints.up("md")} {
    margin-top: 10px;
  }

  ${theme.breakpoints.down("sm")} {
    margin-right: 10px;
    span {
      font-size: 0;
    }
  }
`

const CarouselContainer = styled.div`
  margin-bottom: 28px;
  margin-top: 28px;

  ${theme.breakpoints.up('md')} {
    margin-left: -36px;
  }
`

const GreyBg = styled.div`
  background-color: ${theme.palette.lightGrey};
  padding: 30px 0;
  margin: 30px 0 0;

  ${theme.breakpoints.up('md')} {
    padding: 60px 0;
    margin: 60px 0 0;
  }
  ${theme.breakpoints.up('lg')} {
    padding: 100px 0;
    margin: 100px 0 0;
	}
`
const CaravanCardWrapper = styled(Box)`
  height: 100%;
`

const CaravanCardContainer = styled.div`
  max-width: 500px;
  margin: 0 auto 20px;
  height: 100%;
`

const OtherItems = styled.div `
  margin-top: 26px;
  ${theme.breakpoints.up('lg')} {
    margin-top: 36px;
  }
`

const LinkContainer = styled.div `

  ${theme.breakpoints.up("md")} {
   
  }
  ${props => props.absolute === 1 ? `
    margin-bottom: 20px;
    position: absolute;
    right: 0;
    top: -20px;
    margin-top: 0;
    ${theme.breakpoints.up("md")} {
      top: -10px;
    }

    ${theme.breakpoints.up("lg")} {
      top: 0;
    }
      
  ` : `
    ${theme.breakpoints.up("lg")} {
      margin-top: -65px;
    }
  `}


`

const EnquiryFormContainer = styled.div `
  background: #F3F4F7;
  padding: 0 20px 15px;
  height: 100%;
  position: relative;
  &:before {
      content: '';
      height: calc(100% + 100px);
      width: 10000px;
      position: absolute;
      background: #F3F4F7;
      top: 0;
      left: 0;
      z-index: -1;
  }

  ${theme.breakpoints.up('sm')} {
    padding: 60px 30px;
  }

  ${theme.breakpoints.up('lg')} {
    padding: 60px 80px;
  }

`

const CustomGrid = styled(Grid)`
  ${theme.breakpoints.up('md')} {
    max-width: 1490px !important;
    padding: 0 30px !important;
  }

  ${theme.breakpoints.only('md')} {
    margin-top: 20px !important;
  }
`

const Repayment = styled(Text)`
  text-transform: none;
`

const LinkWrapper = styled.div`
  position: relative;

  ${theme.breakpoints.down('sm')} {
    padding-right: 75px
  }
`

const ContentContainer = styled.div`
  max-width: 650px;
`



const BreadcrumbsContainer = styled.div`
  margin-bottom: 30px;

  ${props => props.margin === 1 && `
    margin-top: 30px;
  `}
`

const StockItem = (props) => {

  const {
    allStock,
    data,
    onStockCardClick,
    onGoBack,
    model,
    dealerId,
    dealerName,
    calculatorModal,
    breadcrumbs,
    onBreadcrumbClick,
    displayFinancePrice
  } = props

  // console.log('sp', props);

  const [tab, setTab] = useState(0);
  const [otherItems, setOtherItems] = useState([]);
  const [loading, setLoading] = useState(allStock ? false : true);
  const [modal, openModal] = useState(false);
  const [calcBannerModal, setCalcBannerModal] = useState(false);

  useEffect(() => {
    setLoading(false);
  }, [allStock]);

  useEffect(() => {
    if (!loading && !data.id && allStock.length > 0) {
      window.location.href = '/error'
    }
  }, [loading]);

  useEffect(() => {

    if (data.price) {
      const arrData = allStock.filter(item => item.id !== data.id);
      const closestPriceItems = getClosestNumber(data.driveAwayPrice, arrData, 'price', arrData.length < 4 ? arrData.length : 4);
      setOtherItems(closestPriceItems);
      return
    }

    if (data.driveAwayPrice) {
      const arrData = allStock.filter(item => item.id !== data.id)
      const closestPriceItems = getClosestNumber(data.driveAwayPrice, arrData, 'driveAwayPrice', arrData.length < 4 ? arrData.length : 4);
      setOtherItems(closestPriceItems);      
    }

    // const otherItems = allSto

  }, [data, allStock]);

  const getYear = (date) => {
    const s = date.toString();
    return s.substr(s.length - 4);
  }

  const tableData = () => {
    let rows = [{
      
    }];

    data.type && rows.push({
      "rowContent": [
        {
          "title": "Type",
        },
        {
          "title": data.type
        }
      ]
    })
    data.stockNumber && rows.push({
      "rowContent": [
        {
          "title": "Stock number",
        },
        {
          "title": data.stockNumber
        }
      ]
    })
    data.class && rows.push({
      "rowContent": [
        {
          "title": "Category",
        },
        {
          "title": data.class
        }
      ]
    })
    data.make && rows.push({
      "rowContent": [
        {
          "title": "Brand",
        },
        {
          "title": data.make
        }
      ]
    })
    data.model && rows.push({
      "rowContent": [
        {
          "title": "Model",
        },
        {
          "title": data.model
        }
      ]
    })
    data.series && rows.push({
      "rowContent": [
        {
          "title": "Series",
        },
        {
          "title": data.series
        }
      ]
    })
    data.buildDate && rows.push({
      "rowContent": [
        {
          "title": "Year",
        },
        {
          "title": getYear(data.buildDate)
        }
      ]
    })
    data.tare && rows.push({
      "rowContent": [
        {
          "title": "Tare",
        },
        {
          "title": data.tare
        }
      ]
    })
    data.regoNumber && rows.push({
      "rowContent": [
        {
          "title": "Plate",
        },
        {
          "title": data.regoNumber
        }
      ]
    })
    data.vin && rows.push({
      "rowContent": [
        {
          "title": "VIN",
        },
        {
          "title": data.vin
        }
      ]
    })
    data.body && rows.push({
      "rowContent": [
        {
          "title": "Body",
        },
        {
          "title": data.body
        }
      ]
    })
    return rows
  }

  const id = data.dealerId ? data.id : data.stockNumber;

  const isSaved = () => {
    const items = getItems();
    return items && items.length > 0 ? (findIndex(items, { id: id} ) !== -1 ? true : false) : false
  }
  const [saved, setSaved] = useState(isSaved())

  const handleLsChange = () => {
    const isItemSaved = isSaved();
    isItemSaved !== saved && setSaved(isItemSaved);
  }

  useEffect(() => {
    document.addEventListener("ls:change", handleLsChange);
    return () => window.removeEventListener("ls:change", handleLsChange);
  },[])

  const handleSave = () => {
    const saveData = {
      id:data.dealerId ? data.id : data.stockNumber,
      image: data.images[0] || null, //@todo
      name: data.name,
      title: data.name,
      uri: `${document.location.pathname}${document.location.search}`,
    };
    const saved = isSaved(id);
    setSaved(!saved);
    (id && saved) ? removeItem({type: 'model', id, data: saveData}) : saveItem({type: 'model', id, data: saveData});
  }

  const handleCtaClick = (href) => {
    if (href.indexOf('book-a-viewing') !== -1) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Request a Viewing',
        'eventCategory': 'Research',
        'eventLabel': href  
      })
    }
    if (href.indexOf('contact-us') !== -1) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Make an Enquiry',
        'eventCategory': 'Research',
        'eventLabel': href  
      })
    }
    if (href.indexOf('#share') !== -1) {
      dlPush({
        'event': 'jaycoEvent',
        'eventAction': 'CTA Click - Share',
        'eventCategory': 'Research',
        'eventLabel': href
      })
    }
  }

  const handleCalcLaunch = (e) => {

    if (e) {
        e.preventDefault();
    }

    setCalcBannerModal(!calcBannerModal);
    
  };

  const suburbDealer = props.dealerSuburbs.length > 0 ? props.dealerSuburbs.find(item => {
      if (item.id === '14680') {
        console.log('sldkjksdjklfsjsdf', props.data.dealerId, item.id, props.data.dealerId === item.id)
      }
      if (item.id === props.data.dealerId) {
        return true;
      }
  }) : null;

  const dealerAddress = suburbDealer ? {
    ...data.dealerAddress,
    suburb: suburbDealer ? suburbDealer.address.suburb : undefined
  } : data.dealerAddress


  return (
    <>
      {!data.id ?
        null
      :
        <Container>
          {(data.images && data.images.length > 0) &&
            <ModalGallery
              open={modal === false ? false : true} 
              activeIndex={modal}
              onClose={() => openModal(false)}
              items={data.images.map(item => {
                return {
                  img: item,
                  width: 900,
                  height: 675
                }
              })}
            />
          }

          {calculatorModal && 
              <>
                <CalculatorBannerModal
                    dealerId={get(props, 'data.dealerId', undefined)}
                    open={calcBannerModal}
                    onModalClose={() => setCalcBannerModal(false)}
                    bannerContent={calculatorModal}
                    make={data.make} // Jayco
                    price={data.driveAwayPrice}
                    model={data.model} // All Terrain
                    variant={data.series || ''} // JPOD.SP-MY23 or should it be 16ft?
                    vin={data.vin}
                />
              </>
          }

          <CustomGrid container direction={'row'} wrap="wrap">
            <GridOrder item xs={12} sm={6} lg={7}>
              <Box px={2}>
                <ContentContainer>
                  {props.breadcrumbs && 
                    // <CustomGrid container direction={'row'} wrap="wrap">
    
                          <BreadcrumbsContainer margin={1}>
                            <Breadcrumbs items={props.breadcrumbs} onClick={onBreadcrumbClick}/>
                          </BreadcrumbsContainer>

                    // </CustomGrid>
                  }
                
                  <LinkWrapper>
                    <LinkContainer absolute={onGoBack ? 0 : 1}>
                      {onGoBack &&
                        <LinkItem>
                          <Links href={props.goBackUrl} svg={chevronLeft} hasA={false}>
                            <a onClick={onGoBack}>
                              Go back
                            </a>
                          </Links>
                        </LinkItem>
                      }
                      <LinkItem>
                        <Links href="#" svg={saved ? savedSvg : saveSvg} stroke onClick={handleSave}>
                          {saved ? 'SAVED' : 'SAVE'}
                        </Links>
                      </LinkItem>
                      <LinkItem>
                        <SocialShare onLaunch={() => handleCtaClick('#share')}/>
                      </LinkItem>

                    </LinkContainer>
                    <Text variant="h3" colour="vividBlue">{data.model}</Text>
                    {data.driveAwayPrice && data.driveAwayPrice.toString() !== '0' ? <Text variant="h4" colour="charcoal">${toPrice(data.driveAwayPrice)}</Text> : null}
                    {displayFinancePrice && data.weeklyRepayment && data.weeklyRepayment.toString() !== '0' ? <Repayment variant="subtitle1" colour="charcoal">FROM ${toPrice(data.weeklyRepayment)} per week*</Repayment> : null}
                    {data.vin && <div data-vin={data.vin}><Text variant="body1">VIN: <span className="vin">{data.vin}</span></Text></div>}
                  </LinkWrapper>
                  <CarouselContainer>
                    {data.images &&
                      <ThumbnailCarousel
                        onImgClick={openModal}
                        items={data.images.map(item => {
                          const lowerCaseType = data.type.toLowerCase() || ''
                          return {
                            thumbnail: item,
                            image: item,
                            labelBg: data.type.toLowerCase() === 'used' ? 'skyBlue' : 'vividBlue',
                            label: lowerCaseType.charAt(0).toUpperCase() + lowerCaseType.slice(1),
                            width: 900,
                            height: 675
                          }
                        })}
                      />
                    }
                  </CarouselContainer>

                  <TabsContainer>
                    <Tabs
                      value={tab}
                      data={[
                        {
                          title: 'Description'
                        },
                        {
                          title: 'Details'
                        }
                      ]}
                      onChange={(e) => setTab(e)}
                    />
                  </TabsContainer>
                  <Content>
                    {tab === 0 ?
                    <Text variant="body1">
                      <div dangerouslySetInnerHTML={{__html: data.vehicleDescription}} />
                    </Text>
                      : 
                    <TableWrapper>
                      <Table
                        key={`table_`}
                        sideBorder={false}
                        row={tableData()}

                      />
                    </TableWrapper>
                    }
                  </Content>
                  <Ctas>
                    {/* <CtaItem>
                      <Cta
                        href={'/book-a-viewing?stock'}
                        onClick={() => handleCtaClick('/book-a-viewing?stock')}
                      >
                        Request a viewing
                      </Cta>
                    </CtaItem> */}
                    {/* <CtaItem>
                      <Cta
                        outline
                        secondary
                        href={'/contact-us'}
                        onClick={() => handleCtaClick('/contact-us')}
                      >
                        Make an enquiry
                      </Cta>
                    </CtaItem> */}
                  </Ctas>
                </ContentContainer>
              </Box>
            </GridOrder>
            <GridOrder item xs={12} sm={6} lg={5}>
             

                <EnquiryFormContainer>
                  <StockEnquiryForm 
                    modelId={model}
                    dealerId={data.dealerId ? data.dealerId : null}
                    dealerName={data.dealerName ? data.dealerName : null}
                    title={data.dealerName ? `${data.dealerName}` : `Contact Dealer`}
                    dealerAddress={dealerAddress}
                    calculatorModal={calculatorModal}
                    onCalcModalClick={handleCalcLaunch}
                  />

                </EnquiryFormContainer>
            
            </GridOrder>

          </CustomGrid>
          {otherItems && otherItems.length > 0 &&
            <GreyBg>
              <Grid container direction={'row'} wrap="wrap">
                <Grid item xs={12}>
                  <Text variant="h4" colour="charcoal" uppercase align="center">Not what you're looking for?</Text>
                  <Text variant="body1" align="center"><p>You may be interested in...</p></Text>
                </Grid>
              </Grid>
              <OtherItems>
                <Grid container direction={'row'} className={'hasPadding'}>
                  {otherItems.map(item => (
                    <Grid item xs={12} sm={6} lg={3} key={item.id}>
                      <CaravanCardWrapper px={2}>
                        <CaravanCardContainer>
                          <StockCard
                            {...item}
                            price={item.price ? `$${toPrice(item.price)}` : null}
                            onClick={onStockCardClick}
                          />
                        </CaravanCardContainer>
                      </CaravanCardWrapper>
                    </Grid>
                  ))}
                </Grid>
              </OtherItems>
            </GreyBg>
          }
        </Container>
      }
    </>
  )
}
  
StockItem.propTypes = {
  data: propTypes.object,
  onStockCardClick: propTypes.func,
  allStock: propTypes.array,
  onGoBack: propTypes.func,
  goBackUrl: propTypes.string,
  displayFinancePrice: propTypes.bool
}
  
StockItem.defaultProps = {
  onStockCardClick: () => {},
  onGoBack: null,
  data: {},
  allStock: null,
  goBackUrl: '/stock',
  displayFinancePrice: true
}
  
export default StockItem